<template>
    <div id="top-bar" :class="classObect()">
        <div class="container clearfix">

            <div class="row justify-content-between">
                <div class="col-12 col-md-auto">

                    <!-- Top Links
                    ============================================= -->
                    <div class="top-links">
                        <ul class="top-links-container">
                            <li class="top-links-item"><a href="/">Home</a></li>
                            <li class="top-links-item"><a href="/contact">Contact</a></li>
                            <li class="top-links-item"><a href="#"><img src="../assets/demos/seo/images/flags/eng.png" alt="Lang">Eng</a>
                                <ul class="top-links-sub-menu" style="display: none">
                                    <li class="top-links-item"><a href="#"><img src="../assets/demos/seo/images/flags/fre.png" alt="Lang">Russian</a></li>
                                    <li class="top-links-item"><a href="#"><img src="../assets/demos/seo/images/flags/ara.png" alt="Lang">Romanian</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div><!-- .top-links end -->

                </div>

                <div class="col-12 col-md-auto dark">

                    <!-- Top Social
                    ============================================= -->
                    <ul id="top-social">
                        <li><a href="mailto:craftytechceo@yahoo.com" class="si-email3"><span class="ts-icon"><i class="icon-envelope-alt"></i></span><span class="ts-text">craftytechceo@yahoo.com</span></a></li>
                    </ul><!-- #top-social end -->

                </div>
            </div>

        </div>
    </div>
    
</template>

<script>
    export default {
        name: "TopBar",
        props:['route'],
        methods: {
            classObect() {
                return {
                    'dark': (this.route !== 'Home'),
                    'transparent-topbar': true
                }
            }
        }
    }
</script>

<style scoped>
    #top-social li a:hover .ts-text {
        max-width: 300px;
        padding-right: 12px;
        transition: all .4s ease;
    }
    .top-bar-sticky {
        position: fixed !important;
        width: 100%;
        top: 0;
        background: white !important;
        left: 0;
        margin-top: 0 !important;
        z-index: 999;
    }
    .top-bar-sticky.dark {
        background: #282828 !important;
    }

</style>